
import './Home.css';
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import MenuItemsList from '../menu/MenuItemsList';
import MenuItemDetail from '../menu/MenuItemDetail';
import Constants from '../../config/Constants';
import Error from '../error/Error'
import ConsoleLog from '../../utils/debugging/ConsoleLog';
import Sheet from 'react-modal-sheet';
import MenuDetail from '../menu/MenuDetail';
import moreHorizontalImage from '../../images/more_horiz.png'
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from "firebase/app";
import IsLinkValid from '../utils/IsLinkValid';
import LoadingSpinner from '../progress/LoadingSpinner';


function Home() {
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [searchParams, setSearchParams] = useSearchParams();
    const menuId = searchParams.get("menu_id");
    
    const [loading, setLoading] = useState(false)
    const [menu, setMenu] = useState(null)
    const [menuItems, setMenuItems] = useState([])
    const [ownerUser, setOwnerUser] = useState(null)
    const [ownerSubscriptionEnded, setOwnerSubscriptionEnded] = useState(false)
    const [apiError, setApiError] = useState(null)

    const [isOpen, setOpen] = useState(false);
    const [selectedMenuItem, setSelectedMenuItem] = useState(null)
    const [isMenuDetailOpen, setMenuDetailOpen] = useState(false);
 
    useEffect(() => {

      // Initialize Firebase
        const app = initializeApp(Constants.FIREBASE_CONFIG);
        const analytics = getAnalytics(app);
        logEvent(analytics, 'menu_opened', {
            menu_id: menuId,
        });

        fetchMenu();
        //fetchMenuItems()

        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener('resize', handleWindowResize);
      
        return () => {
        window.removeEventListener('resize', handleWindowResize);
        };
    }, [])


    const MenuURL = `${Constants.BACKEND_URL}menu/${menuId}`
    const fetchMenu = () => {
        setLoading(true);
        fetch(MenuURL)
            .then((res) =>
                res.json())
 
            .then((response) => {
                ConsoleLog(response);
                setLoading(false);
                if (response.hasOwnProperty('status') && response.hasOwnProperty('message')) {
                    setLoading(false);
                    setApiError(response);
                }
                else {
                    setApiError(null);
                    setMenu(response);
                    fetchEncapsulatedUser(response.user_id)
                }
            })
 
    }

    const MenuItemsURL = `${Constants.BACKEND_URL}menuitembymenu?menu_id=${menuId}&search_text=`
    const fetchMenuItems = () => {
        setLoading(true);
        fetch(MenuItemsURL)
            .then((res) =>
                res.json())
 
            .then((response) => {
                ConsoleLog(response);
                setLoading(false);
                if (response.hasOwnProperty('status') && response.hasOwnProperty('message')) {
                    setApiError(response);
                }
                else {
                    setApiError(null);
                    setMenuItems(response);
                }
            })
 
    }
    
    const fetchEncapsulatedUser = (userId) => {
        setLoading(true);
        fetch(`${Constants.BACKEND_URL}user/encapsulated/${userId}`)
            .then((res) =>
                res.json())
 
            .then((response) => {
                ConsoleLog(response);
                setLoading(false);
                if (response.hasOwnProperty('status') && response.hasOwnProperty('message')) {
                    setLoading(false);
                    setApiError(response);
                }
                else {
                    setApiError(null);
                    setOwnerUser(response);
                    var subscription_valid_until = Date.parse(response.subscription_valid_until);
                    var dateToday = WithoutTime(new Date());
                    var subscriptionEnded = dateToday > subscription_valid_until;
                    setOwnerSubscriptionEnded(subscriptionEnded);
                    if (!subscriptionEnded){
                        fetchMenuItems();
                    }
                }
                
            })
 
    }

    const handleMenuItemClick = menuItem => {
        // 👇️ take parameter passed from Child component
        const analytics = getAnalytics();
        logEvent(analytics, 'menu_item_clicked', {
            menu_id: menu.id,
            menu_name: menu.name,
            menu_item_id: menuItem.id,
            menu_item_name: menuItem.name,
        });

        setSelectedMenuItem(menuItem);
        setOpen(false);     //HACK
        setOpen(true);
      };

      const handleCloseMenuItem = () => {
        setOpen(false);  
      };

    const handleMenuDetailsClick = () => {
        const analytics = getAnalytics();
        logEvent(analytics, 'menu_detail_opened', {
            menu_id: menu.id,
            menu_name: menu.name,
        });

        setMenuDetailOpen(false); 
        setMenuDetailOpen(true);
    }

    const handleCloseMenuDetail = () => {
        setMenuDetailOpen(false); 
      };
      
      var firstLetter = "";
      if (menu !== null && menu !== undefined && menu.name !== undefined && menu.name !== null && menu.name.length > 0){
        firstLetter = menu.name.charAt(0)
      }


    var isValidBannerPresent = menu !== null && menu !== undefined && menu.banner !== undefined && menu.banner !== null && IsLinkValid(menu.banner.url);

    return (
        <div className='home_root_container'>
            {loading ? <LoadingSpinner /> : 
            <div>
                {isValidBannerPresent && windowSize.innerWidth >= Constants.DESKTOP_MODE_MIN_WIDTH ? <img src={menu.banner.url} alt={menu.name} className="home_menu_banner" /> : null}
                <table>
                    <tbody>
                        <>
                            <tr>
                                <td className='menu_title_table_data_photo'>
                                {(menu !== null && menu !== undefined && menu.photo !== undefined && menu.photo !== null) ? <img src={menu.photo.url} alt={menu.name} className="menu_title_photo" />
                                : 
                                <div className='menu_title_name_first_letter'>{firstLetter}</div> }            
                                </td>
                                <td className='menu_title_table_data_info_column'>
                                    <div className='welcome_text'>Welcome to,</div>
                                    <div className='menu_name'>{menu != null ? menu.name : ""}</div>
                                </td>
                                <td className='menu_title_table_options_column'>
                                    <button className='view_menu_detail_button' onClick={handleMenuDetailsClick}>{windowSize.innerWidth >= Constants.DESKTOP_MODE_MIN_WIDTH ? <span>About&nbsp;&nbsp;</span> : null} <img src={moreHorizontalImage} alt="View Menu Detail" /></button>
                                </td>
                            </tr>
                        </>
                    </tbody>
                </table>
           

            <Sheet className="home_sheet" isOpen={isOpen} detent="content-height" onClose={() => setOpen(false)}>
                <Sheet.Container>
                <Sheet.Header />
                <Sheet.Content>
                    <MenuItemDetail data={selectedMenuItem} onClose={handleCloseMenuItem} />
                    </Sheet.Content>
                </Sheet.Container>

                <Sheet.Backdrop />
            </Sheet>

            <Sheet className="home_sheet" isOpen={isMenuDetailOpen} detent="content-height" onClose={() => setMenuDetailOpen(false)}>
                <Sheet.Container>
                <Sheet.Header />
                <Sheet.Content>
                    <MenuDetail data={menu}  onClose={handleCloseMenuDetail} />
                    </Sheet.Content>
                </Sheet.Container>

                <Sheet.Backdrop />
            </Sheet>

           {/**  <h2>Width: {windowSize.innerWidth}</h2>  */}
            {
                apiError != null ? <Error title="Api Error" description={apiError.message} />
                :
                (
                    ownerSubscriptionEnded ? <Error title="Subscription Expired" description="The subscription of the owner of this menu has expired. Please contact the menu owner to renew their subscription in order for patrons to keep viewing this menu." /> 
                    :
                    (menuItems != null ? <MenuItemsList data={menuItems} handleMenuItemClick={handleMenuItemClick} /> : <Error title="Error" description="Menu Items list could not be fetched" />) 
                )
            }
             </div>}
        </div>
    );
}

function WithoutTime(dateTime) {
    var date = new Date(dateTime.getTime());
    date.setHours(0, 0, 0, 0);
    return date;
}
 
export default Home;

function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
}
