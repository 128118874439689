
import React from 'react';
import './MenuItemDesktop.css';
import './MenuItem.css';
import Error from '../error/Error'
import expand_more_img from '../../images/expand_more.png'
import placeholderImage from '../../images/menu_item_placeholder.jpg'

function MenuItemDesktop(props) {
   
    if (props.data !== null && props.data !== undefined && props.itemKey !== null && props.itemKey !== undefined ){
        let currency = Intl.NumberFormat("en-US", {
            style: "currency",
            currency: (props.data.currencycode === null || props.data.currencycode === undefined || props.data.currencycode === "") ? "ZAR" : props.data.currencycode,
            currencyDisplay: 'narrowSymbol',
        });

        return (
            <> 
            <div className='menu_item_desktop_container' key={props.itemKey} onClick={() => props.handleMenuItemClick(props.data)}>
                <div> 
                    <img src={(props.data.photo !== undefined && props.data.photo !== null) ? props.data.photo.url : placeholderImage} alt={props.data.name} className="menu_item_desktop_cell_photo" />
                </div>
                <div className='menu_item_desktop_info_row'>
                    <div className='menu_item_cell_title'>{props.data.name}</div>
                    <div className='menu_item_cell_description'>{props.data.description}</div>
                    <div className='test'>
                        <div className='menu_item_cell_price_and_view_more_parent_container'>
                            {currency.format(props.data.price).replace(/\s+/g, '')}
                            <button className='menu_item_cell_view_more_button' onClick={() => props.handleMenuItemClick(props.data)}>View More <img src={expand_more_img} alt="View More" /></button>
                        </div>
                    </div>
                  {/**  <div>{props.data.foodtype !== undefined ? props.data.foodtype.name : ""}</div>  */} 
                </div>
            </div>
     
            </>
        );
    }
    else {
        return (
            <Error title="Error" description="Something went wrong. Menu item could not be displayed."/>
        );
    }
}
 
export default MenuItemDesktop;
