

function IsLinkValid(url){
    try { 
        return (url !== null && url !== undefined && url.length > 0 && Boolean(new URL(url)));
    }
    catch(e){ 
        return false; 
    }
}

export default IsLinkValid;