import React from 'react'
//import { Link } from 'react-router-dom'

import './Footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faInstagram, faLinkedin, faFacebook } from "@fortawesome/free-brands-svg-icons"
import { faGlobe, faEnvelope } from '@fortawesome/free-solid-svg-icons'; 

const MenuBar = () => {
    return (
        <footer >
            <div className="footer">
                <ul>
                  {/**  <li><Link to="/">Home</Link></li>
                     <li><Link to="/PrivacyPolicy">Privacy Policy</Link></li>  
                    <li><Link to="/AboutUs">About Us</Link></li>  
                    <li><Link to="/ContactUs">Contact Menu Linq</Link></li>
                    */}
                </ul>

                <p>ⓒ MenuLinq 2023. All rights reserved.</p>

                 {/**   <li>
                    
                        <a href="https://www.facebook.com"><FontAwesomeIcon icon={faFacebook} /></a>
                    </li> */}  
                    <li>
                        <a href="https://www.twitter.com/menulinq" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faTwitter} size="lg" /></a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/menulinq" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram} size="lg"   /></a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/company/menulinq/"  target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedin} size="lg"  /></a>
                    </li> 
                    <li>
                        <a href="https://www.facebook.com/people/MenuLinq/100088184777603/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebook} size="lg"  /></a>
                    </li> 
                   <li>
                        <a href="https://www.menulinq.com" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faGlobe} size="lg"  /></a>
                    </li>
                    <li>
                        <a href="mailto:info@menulinq.com"><FontAwesomeIcon icon={faEnvelope} size="lg" /></a>
                    </li>

            {/**     <p><a href="https://kardiak.co.za"><img src={credit} alt="Kardiak" width={80} /></a></p>   */}
            </div>
        </footer>
    )
}

export default MenuBar;