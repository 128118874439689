import React from 'react';
import './AllergenButton.css';

function AllergenButton(props) {

    const openInNewTab = url => {
       // window.open(url, '_blank', 'noopener,noreferrer');
      };

    return (
        <button className='allergen_link_button' onClick={() => openInNewTab(props.url)}><span>{props.text}</span></button>
    );

}

export default AllergenButton;