
import React from 'react';
import './Error.css'

function Error(props) {

    return (
        <>
            <div>
                <div className='error_title'>{props.title}</div>
                <div className='error_description'>{props.description}</div>
                
            </div>
 
        </>
    );
}
 
export default Error;