import React from "react";
import "./LoadingSpinner.css";
import faviconImage from '../../images/favicon.png'

export default function LoadingSpinner() {
  return (
    <div className="spinner-container">
      <div className="loading-spinner">
        <img className="spinner-image" src={faviconImage} alt="logo" />
      </div>
    </div>
  );
}