
import React from 'react';
import './MenuDetail.css';
import Error from '../error/Error'
import LinkButton from '../buttons/LinkButton';
import IsLinkValid from '../utils/IsLinkValid';

function MenuDetail(props) {

    var firstLetter = "";
    var isValidLogoPresent = props.data.photo !== undefined && props.data.photo !== null && IsLinkValid(props.data.photo.url);
    var isValidBannerPresent = props.data.banner !== undefined && props.data.banner !== null && IsLinkValid(props.data.banner.url);


    if (props.data !== null && props.data !== undefined){

        if (props.data.name !== undefined && props.data.name !== null && props.data.name.length > 0){
            firstLetter = props.data.name.charAt(0)
        }

        return (
            <> 
                <div className='menu_detail_parent_container'>
                    {/**windowSize.innerWidth >= Constants.DESKTOP_MODE_MIN_WIDTH 
                    ? 
                    <div className='menu_item_detail_close_image_container'>
                        <img className={props.data.photo !== undefined && props.data.photo !== null && IsLinkValid(props.data.photo.url) ? 'menu_item_detail_close_image' : 'menu_item_detail_close_image_for_no_photo'} onClick={props.onClose} src={CloseImage} alt="Close"/>
                    </div>
                    : null **/}
                    {isValidBannerPresent ? <img src={props.data.banner.url} alt={props.data.name} className="menu_detail_banner" /> : null }
                    {isValidBannerPresent 
                    ? 
                    (isValidLogoPresent ? <div><img src={props.data.photo.url} alt={props.data.name} className="menu_detail_logo" /></div> : <div className='menu_detail_name_first_letter'>{firstLetter}</div>)
                    :
                        <table className='menu_detail_no_banner_title_table'>
                            <tbody>
                                <>
                                    <tr>
                                        <td className='menu_detail_title_table_data_photo'>
                                            {isValidLogoPresent ? <div><img src={props.data.photo.url} alt={props.data.name} className="menu_detail_logo_for_no_banner" /></div> : <div className='menu_detail_name_first_letter_for_no_banner'>{firstLetter}</div>}        
                                        </td>
                                        <td className='menu_detail_title_table_data_info_column'>
                                            <div className='menu_detail_title'>{props.data != null ? props.data.name : ""}</div>
                                        </td>
                                    </tr>
                                </>
                            </tbody>
                        </table>
                    }
                    
                    
                    
                    {isValidBannerPresent ? <div className='menu_detail_title'>{props.data.name}</div> : null}
                    <div className='menu_detail_description' >{props.data.description}</div>
                    

                    {
                        (IsLinkValid(props.data.facebook_link) || IsLinkValid(props.data.instagram_link) || IsLinkValid(props.data.linkedin_link)  || IsLinkValid(props.data.trip_advisor_link)  || IsLinkValid(props.data.twitter_link)  || IsLinkValid(props.data.website_link)  || IsLinkValid(props.data.yelp_link)  )
                        ? 
                        <div className='menu_detail_find_us' >Find us on</div> : null 
                    }
                    <div>
                        {IsLinkValid(props.data.facebook_link) ? <LinkButton url={props.data.facebook_link} text="Facebook" /> : null }
                        {IsLinkValid(props.data.instagram_link) ? <LinkButton url={props.data.instagram_link} text="Instagram" /> : null }
                        {IsLinkValid(props.data.linkedin_link) ? <LinkButton url={props.data.linkedin_link} text="LinkedIn" /> : null }
                        {IsLinkValid(props.data.trip_advisor_link) ? <LinkButton url={props.data.trip_advisor_link} text="Trip Advisor" /> : null }
                        {IsLinkValid(props.data.twitter_link) ? <LinkButton url={props.data.twitter_link} text="Twitter" /> : null }
                        {IsLinkValid(props.data.website_link) ? <LinkButton url={props.data.website_link} text="Website" /> : null }
                        {IsLinkValid(props.data.yelp_link) ? <LinkButton url={props.data.yelp_link} text="yelp_link" /> : null }                     
                        </div>
                        <div class="menu_detail_close_button_container">
                            <div>
                                <button className='menu_detail_close_button' onClick={props.onClose}>Close</button>
                            </div>
                        </div>
                </div>
            </>
        );
    }
    else {
        return (
            <Error title="Error" description="Something went wrong. Menu item could not be displayed."/>
        );
    }

}

export default MenuDetail;
