import React from 'react';
import './LinkButton.css';
import linkImage from '../../images/link.png'
import { getAnalytics, logEvent } from 'firebase/analytics';

function LinkButton(props) {

    const openInNewTab = url => {
        const analytics = getAnalytics();
        logEvent(analytics, 'link_clicked', {
            url: url,
        });
        window.open(url, '_blank', 'noopener,noreferrer');
      };

    return (
        <button className='link_button' onClick={() => openInNewTab(props.url)}><img className='link_button_icon' src={linkImage} alt="Trip Advisor link" /><span>{props.text}</span></button>
    );

}

export default LinkButton;