
const Constants = {
    BACKEND_URL : "https://xy1u-afpf-cl4x.n7.xano.io/api:On1F-I7M/",
    DEBUG_MODE : false,
    DESKTOP_MODE_MIN_WIDTH : 1000,

        // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    FIREBASE_CONFIG : {
    apiKey: "AIzaSyAnM3XbxImNy1GT6J8ttsaSvf4cSH2csws",
    authDomain: "menulinq-menu.firebaseapp.com",
    projectId: "menulinq-menu",
    storageBucket: "menulinq-menu.appspot.com",
    messagingSenderId: "647928129243",
    appId: "1:647928129243:web:5da005f7ee74bab8e3cefe",
    measurementId: "G-WYNDK451FL"
  },
}

export default Constants;