import React, { useEffect, useState } from 'react'
import './MenuBar.css'
import { useSearchParams } from 'react-router-dom';
import Constants from '../../config/Constants';

const MenuBar = () => {
 /*   const [searchParams, setSearchParams] = useSearchParams();
    const menuId = searchParams.get("menu_id");

    const [menu, setMenu] = useState(null)

    useEffect(() => {
        fetchMenu();
    }, [])

    const MenuURL = `${Constants.BACKEND_URL}menu/${menuId}`
    const fetchMenu = () => {
        fetch(MenuURL)
            .then((res) =>
                res.json())
 
            .then((response) => {
                console.log(response);
                console.log("RESPONSE CODE - " + response.status);
                console.log("RESPONSE MESSAGE - " + response.message);
                setMenu(response);
            })
 
    }

    const getMenuLinqPath = menu != null ? `/GetMenuLinq?menu_id=${menuId}` : "GetMenuLinq";
    const homePath = menu != null ? `/?menu_id=${menuId}` : "/";
    */
    return (
        <div></div>
    )
}

export default MenuBar;