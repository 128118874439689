
import React, { useEffect, useState } from 'react';
import ScrollSpyTabs from '../utils/ScrollSpyTabs';
import MenuItem from './MenuItem';
import './MenuItemsList.css'
import Error from '../error/Error'
import MenuItemDesktop from './MenuItemDesktop';
import Constants from '../../config/Constants';

function MenuItemsList(props) {
    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
          setWindowSize(getWindowSize());
        }
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);

    const handleMenuItemClick = menuItem => {
        // 👇️ take parameter passed from Child component
        props.handleMenuItemClick(menuItem);
      };

 
    if (props.data !== null && props.data !== undefined && props.data.length > 0){
        const visibleItemsOnly = props.data.filter( item => item.hasOwnProperty('visible') && item.visible === true );
        const tempdata = visibleItemsOnly.sort((a) => (a.hasOwnProperty('foodtype')) ? 1 : -1);
        const data = tempdata.sort((a, b) => (a.hasOwnProperty('foodtype') && b.hasOwnProperty('foodtype') && a.foodtype.id > b.foodtype.id) ? 1 : -1);

        var uniqueFoodTypeList = [];
        for (let i = 0; i < data.length; i++){
            if (data[i].hasOwnProperty('foodtype')){
                const result = uniqueFoodTypeList.filter(item => item.hasOwnProperty('id') && item.id === data[i].foodtype.id);
                if (result === null || result === undefined || result.length === 0){
                    uniqueFoodTypeList.push(data[i].foodtype);
                }
            }
        }

        var tabsList = [];
        for (let x = 0; x < uniqueFoodTypeList.length; x++){
            const tabItems = data.filter( item => item.hasOwnProperty('foodtype') && item.foodtype.id === uniqueFoodTypeList[x].id);
            const tab = {
                text: uniqueFoodTypeList[x].name,
                component:   
                  <div>
                      <div className='section_title'>{uniqueFoodTypeList[x].name}</div> 
                      {windowSize.innerWidth < Constants.DESKTOP_MODE_MIN_WIDTH ? 
                      <table>
                        <tbody>
                            {tabItems.map((item, i) => (
                                <MenuItem data={item} key={i} itemKey={i} handleMenuItemClick={handleMenuItemClick}/>
                            ))}
                        </tbody>
                      </table>
                      :
                    <div className='desktop_grid_container'>
                        {tabItems.map(function(item, key){
                            return (<MenuItemDesktop data={item} key={key} itemKey={key} handleMenuItemClick={handleMenuItemClick}/>)
                        })}
                    </div>
                            
                    }
                  </div> 
              }
              tabsList.push(tab);
        }

    

        return (
            <>
                {tabsList.length > 0 ?  <ScrollSpyTabs tabsInScroll={tabsList}/> :  <Error title="Empty" description="The menu list is empty. There are no valid menu items to display yet."/> }
            </>
        );
    }
    else if (props.data !== null && props.data !== undefined && props.data.length === 0){
        return (
            <Error title="Empty" description="The menu list is empty. The admin has not added any menu items yet."/>
        );
    }
    else {
        return (
           <Error title="Error" description="Something went wrong."/>
        );
    }
}
 
export default MenuItemsList;

function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
}
