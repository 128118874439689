
import './App.css';
import Home from './components/pages/Home';
import {
  BrowserRouter as Router, 
  Routes,
  Route
} from 'react-router-dom'
import Layout from './layout/Layout';
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useEffect } from 'react';
import Constants from './config/Constants';

function App() {


    useEffect(() => {

      // Initialize Firebase
      const app = initializeApp(Constants.FIREBASE_CONFIG);
      const analytics = getAnalytics(app);
      logEvent(analytics, 'app_initialized');
    }, [])

  

  return (
    
    <div className="body-wrap">
      <Router>
        <Layout>
          <Routes>
           {/**
            *  <Route path={'/AboutUs'} element={<AboutUs/>}/>
            <Route path={'/ContactUs'} element={<ContactUs/>}/>
            <Route path={'/PrivacyPolicy'} element={<PrivacyPolicy/>}/>
            */}
            <Route path={'/'} element={<Home/>}/> 
          </Routes> 
        </Layout>
      </Router>
    </div>
  );
}

export default App;
