import { useEffect, useState } from "react";
import WarningImage from "../../images/warning.png";
import AllergenButton from "../buttons/AllergenButton";
import Error from "../error/Error";
import IsLinkValid from "../utils/IsLinkValid";
import "./MenuItemDetail.css";

function MenuItemDetail(props) {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  if (props.data !== null && props.data !== undefined) {
    let currency = Intl.NumberFormat("en-US", {
      style: "currency",
      currency:
        props.data.currencycode === null ||
        props.data.currencycode === undefined ||
        props.data.currencycode === ""
          ? "ZAR"
          : props.data.currencycode,
      currencyDisplay: "narrowSymbol",
    });

    var allergensList = [];

    if (
      props.data.allergens !== null &&
      props.data.allergens !== undefined &&
      props.data.allergens.length > 0
    ) {
      var _allergens = props.data.allergens;
      if (_allergens.includes(",")) {
        allergensList = _allergens.split(",");
        allergensList = allergensList.filter(function (item) {
          return item.trim().length > 0;
        });
      } else {
        allergensList.push(_allergens.trim());
      }
    }

    var flavoursList = [];

    if (
      props.data.flavours !== null &&
      props.data.flavours !== undefined &&
      props.data.flavours.length > 0
    ) {
      var _flavours = props.data.flavours;
      if (_flavours.includes(",")) {
        flavoursList = _flavours.split(",");
        flavoursList = flavoursList.filter(function (item) {
          return item.trim().length > 0;
        });
      } else {
        flavoursList.push(_flavours.trim());
      }
    }

    var calories = "";
    if (
      props.data.calories !== null &&
      props.data.calories !== undefined &&
      props.data.calories.length > 0
    ) {
      calories = props.data.calories;
    }

    return (
      <>
        <div className="detail_parent_container">
          {/**windowSize.innerWidth >= Constants.DESKTOP_MODE_MIN_WIDTH 
                    ? 
                    <div className='menu_item_detail_close_image_container'>
                        <img className={props.data.photo !== undefined && props.data.photo !== null && IsLinkValid(props.data.photo.url) ? 'menu_item_detail_close_image' : 'menu_item_detail_close_image_for_no_photo'} onClick={props.onClose} src={CloseImage} alt="Close"/>
                    </div>
                    : null **/}
          {props.data.photo !== undefined &&
          props.data.photo !== null &&
          IsLinkValid(props.data.photo.url) ? (
            <img
              src={props.data.photo.url}
              alt={props.data.name}
              className="detail_photo"
            />
          ) : null}
          <div className="detail_title">{props.data.name}</div>
          <div className="detail_description">{props.data.description}</div>
          <div className="detail_price">
            {currency.format(props.data.price).replace(/\s+/g, "")}
          </div>
          <div className="menu_item_detail_divider"></div>

          {flavoursList.length > 0 ? (
            <div className="menu_item_detail_subsection_title">
              <span>Flavours</span>
            </div>
          ) : null}

          {flavoursList.length > 0 ? (
            <div>
              {flavoursList.map(function (flavour, key) {
                return (
                  <AllergenButton key={key} text={flavour.trim()} url="" />
                );
              })}
            </div>
          ) : null}

          {flavoursList.length > 0 ? (
            <div className="menu_item_detail_divider"></div>
          ) : null}

          {allergensList.length > 0 ? (
            <div className="menu_item_detail_subsection_title">
              <img
                className="menu_item_detail_subsection_title_icon"
                src={WarningImage}
                alt="Allergen"
              />
              <span>Allergens in this food</span>
            </div>
          ) : null}

          {allergensList.length > 0 ? (
            <div>
              {allergensList.map(function (allergen, key) {
                return (
                  <AllergenButton key={key} text={allergen.trim()} url="" />
                );
              })}
            </div>
          ) : null}

          {allergensList.length > 0 ? (
            <div className="menu_item_detail_divider"></div>
          ) : null}

          {calories.length > 0 ? (
            <div className="menu_item_detail_subsection_title">
              <span>Calories per 100g/100ml</span>
            </div>
          ) : null}

          {calories.length > 0 ? (
            <AllergenButton text={calories.trim()} url="" />
          ) : null}

          {calories.length > 0 ? (
            <div className="menu_item_detail_divider"></div>
          ) : null}

          {/*** HACK: Add bottom space when there is no image available. This is because there is a bug on the Sheet component when the height of the content is very small. */}
          {props.data.photo !== undefined &&
          props.data.photo !== null &&
          IsLinkValid(props.data.photo.url) ? null : (
            <div>
              <br /> <br /> <br />
              <br /> <br /> <br />{" "}
            </div>
          )}

          <div class="menu_item_detail_close_button_container">
            <div>
              <button
                className="menu_item_detail_close_button"
                onClick={props.onClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <Error
        title="Error"
        description="Something went wrong. Menu item could not be displayed."
      />
    );
  }
}

export default MenuItemDetail;

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
